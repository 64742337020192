/*
 * @Description:
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-29 11:30:07
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/operate'
const prefix1 = '/order'

// 根据车牌号车牌颜色查询欠费订单
export function getArrearsOrder (param) {
  return request({
    url: prefix1 + '/OrderController/getArrearsOrderInfoByPlateNumberAndCode',
    method: 'post',
    data: param
  })
}

// 车行道 修改进场
export function carParkEditOrderAndInPicture (param) {
  return request({
    url: prefix + '/inspection/carParkEditOrderAndInPicture',
    method: 'post',
    data: param
  })
}

// 人行道 修改进场
export function sidewalkParkEditOrderAndInPicture (param) {
  return request({
    url: prefix + '/inspection/sidewalkParkEditOrderAndInPicture',
    method: 'post',
    data: param
  })
}
