/*
 * @Description:
 * @Author: yanxiao
 * @Github:
 * @Date: 2021-04-29 13:44:57
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/order'
const prefix1 = '/operate'

// 修改车牌
export function editOrderAndInPicture (param) {
  return request({
    url: prefix1 + '/order/editOrderAndInPicture',
    method: 'post',
    data: param
  })
}

// 修改车牌
export function editOrder (param) {
  return request({
    url: prefix1 + '/order/editOrder',
    method: 'post',
    data: param
  })
}

// mongo查询停车位
export function getParkSpaceListByParkId (param) {
  return request({
    url: prefix1 + '/ParkSpaceController/getParkSpaceListByParkId',
    method: 'post',
    data: param
  })
}

// 获取有车位
export function getOrderOnlineNoPage (param) {
  return request({
    url: prefix + '/inspection/getOrderOnlineNoPage',
    method: 'post',
    data: param
  })
}

// 巡检员获取车位状态
export function getOrderOnline (param) {
  return request({
    url: prefix + '/inspection/getOrderOnlineNoPage',
    method: 'post',
    data: param
  })
}

// 巡检员确认离场
export function confirmLeave (param) {
  return request({
    url: prefix + '/inspection/confirmLeave',
    method: 'post',
    data: param
  })
}
