/*
 * @Description: 请求接口入口
 * @Author: John Holl
 * @Github: https://github.com/hzylyh
 * @Date: 2021-03-31 20:39:39
 * @LastEditors: zhoucheng
 */

// 登录
import * as login from './login/login.js'
// 首页
import * as homePage from './homePage/homePage.js'
// 下拉菜单
import * as queryDict from './queryDict/queryDict.js'
// 停车订单
import * as parkingOrder from './parkingOrder/parkingOrder.js'
// 车牌登记
import * as register from './signIn/register.js'
// 泊位全景
import * as outCharge from './outCharge/outCharge.js'
// 签到
import * as signIn from './signIn/signIn.js'

// 维护管理
import * as maintentManage from './maintentManage/maintentManage.js'
// 已完成工单
import * as completedWork from './maintentManage/completedWork.js'
// 上报工单
import * as uploadWorkList from './maintentManage/uploadWorkList.js'
// 待确认工单
import * as waitConfirmWork from './maintentManage/waitConfirmWork.js'
// 待处理工单
import * as waitDisposeWork from './maintentManage/waitDisposeWork.js'
// 待复核工单
import * as waitPrecheckWork from './maintentManage/waitPrecheckWork.js'

import * as commonAxios from './commonAxios/commonAxios.js'

const install = function (Vue) {
  if (install.installed) {
    return (install.installed = true)
  }
  Object.defineProperties(Vue.prototype, {
    $commonAxios: { get () { return commonAxios } },
    $homePage: {
      get () {
        return homePage
      }
    },
    $register: {
      get () {
        return register
      }
    },
    $parkingOrder: {
      get () {
        return parkingOrder
      }
    },
    $queryDict: {
      get () {
        return queryDict
      }
    },
    $outCharge: {
      get () {
        return outCharge
      }
    },
    $login: {
      get () {
        return login
      }
    },
    $signIn: {
      get () {
        return signIn
      }
    },
    $maintentManage: {
      get () {
        return maintentManage
      }
    },
    $completedWork: {
      get () {
        return completedWork
      }
    },
    $uploadWorkList: {
      get () {
        return uploadWorkList
      }
    },
    $waitConfirmWork: {
      get () {
        return waitConfirmWork
      }
    },
    $waitDisposeWork: {
      get () {
        return waitDisposeWork
      }
    },
    $waitPrecheckWork: {
      get () {
        return waitPrecheckWork
      }
    }

  })
}

export default {
  install
}
