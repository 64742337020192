/*
 * @Description: 维护管理 待处理工单 waitDisposeWork
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-08-20 14:23:34
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/operate'

// 工单查询
export function queryWorkTicketList (param) {
  return request({
    url: prefix + '/workTicket/queryWorkTicketList',
    method: 'post',
    data: param
  })
}

// 查询运维维护单位
export function queryMaintenance (param) {
  return request({
    url: prefix + '/maintenance/queryMaintenance',
    method: 'post',
    data: param
  })
}
// 查询设备信息
export function queryDeviceInfo (param) {
  return request({
    url: prefix + '/workTicket/queryDeviceInfo',
    method: 'post',
    data: param
  })
}
// 处理工单
export function handleWorkTicket (param) {
  return request({
    url: prefix + '/workTicket/handleWorkTicket',
    method: 'post',
    data: param
  })
}
