/*
 * @Description:首页 停车记录 parkingOrder
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-29 00:36:58
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/order'
const prefix1 = '/bill'

// 欠费合单支付
export function nativePayBatch (param) {
  return request({
    url: prefix + '/wxPublicPay/nativePayBatch',
    method: 'post',
    data: param
  })
}

// 二维码信息
export function nativePay (param) {
  return request({
    url: prefix + '/wxPublicPay/nativePay',
    method: 'post',
    data: param
  })
}
// 获取停车订单
export function getOrderByOrderStatus (param) {
  return request({
    url: prefix + '/inspection/getOrderByOrderStatus',
    method: 'post',
    data: param
  })
}
// 根据车牌号查订单
export function getArrearsOrderInfoByPlateNumberAndCode (param) {
  return request({
    url: prefix + '/OrderController/getArrearsOrderInfoByPlateNumberAndCode',
    method: 'post',
    data: param
  })
}
// 获取订单
export function getOrderCompleted (param) {
  return request({
    url: prefix + '/inspection/getOrderCompleted',
    method: 'post',
    data: param
  })
}
// 计费
export function charge (param) {
  return request({
    url: prefix1 + '/ChargingController/charge',
    method: 'post',
    data: param
  })
}
