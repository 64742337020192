/*
 * @Description:
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-06-13 17:05:12
 * @LastEditors: zhoucheng
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.less'
import './styles/index.less'
import fmtDate from './utils/fmtDate.js'
import api from './api/index'
import storage from './utils/storage.js'

// 高德地图 npm i @amap/amap-jsapi-loader --save-dev // npm install -s vue-amap
import AMap from 'vue-amap'
import publicVar from './utils/publicVar'

// vconsole
// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()
// Vue.use(vConsole)

Vue.use(publicVar)
Vue.use(Vant)
Vue.use(api)
Vue.prototype.$cookie = storage
Vue.prototype.$fmtDate = fmtDate

Vue.config.productionTip = false
AMap.initAMapApiLoader({
  key: 'f3b01243929a23cf9b3ba2deeb49fc4b',
  plugin: [
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.Geolocation'
  ],
  v: '1.4.4'
})
Vue.use(AMap)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
