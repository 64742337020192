/*
 * @Description:
 * @Author: yanxiao
 * @Github:
 * @Date: 2021-04-29 11:30:07
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/operate'
const preFix = '/order'

// 首页展示在停泊位 泊位
export function getParkSpaceStatus (param) {
  return request({
    url: prefix + '/ParkSpaceController/getParkSpaceStatus',
    method: 'post',
    data: param
  })
}

// mongo查询停车位
export function getParkSpaceListByParkId (param) {
  return request({
    url: prefix + '/ParkSpaceController/getParkSpaceListByParkId',
    method: 'post',
    data: param
  })
}

// 获取有车位
export function getOrderOnlineNoPage (param) {
  return request({
    url: preFix + '/inspection/getOrderOnlineNoPage',
    method: 'post',
    data: param
  })
}

// 根据停车场查询应收实收金额
export function getIncome (param) {
  return request({
    url: preFix + '/inspection/getIncome',
    method: 'post',
    data: param
  })
}

export function queryParkList (param) {
  return request({
    url: prefix + '/dict/queryDictWithAuth',
    method: 'post',
    data: param
  })
}

// 无分页查询泊位
export function queryParkLayerList (param) {
  return request({
    url: prefix + '/ParkSpaceController/queryList',
    method: 'post',
    data: param
  })
}
