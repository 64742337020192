/*
 * @Description:getter汇总文件
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-08 13:49:41
 * @LastEditors: yanxiao
 */

const getters = {
  sidebar: state => state.app.sidebar
}

export default getters
