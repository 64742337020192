/*
 * @Description: 路由 注册页面
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-08 13:45:18
 * @LastEditors: zhoucheng
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { Toast } from 'vant'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    redirect: '/login',
    meta: { name: '重定向页面' }
  },
  // 登录页
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
    meta: { name: '用户端-登录页', type: 'list' }
  },
  // 操作台
  {
    path: '/homePage',
    name: 'homePage',
    component: () => import('@/views/homePage/index.vue'),
    meta: { name: '操作台', type: 'list' }
  },

  // 登记
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/berthPanoramic/register/index.vue'),
    meta: { name: '登记', type: 'list' }
  },
  // 泊位全景-车行道
  {
    path: '/outCharge',
    name: 'outCharge',
    component: () => import('@/views/berthPanoramic/outCharge/index.vue'),
    meta: { name: '泊位全景-车行道', type: 'list' }
  },
  // 泊位全景-人行道
  {
    path: '/inCharge',
    name: 'inCharge',
    component: () => import('@/views/berthPanoramic/inCharge/index.vue'),
    meta: { name: '泊位全景-人行道', type: 'list' }
  },
  // 泊位全景-订单详情
  {
    path: '/orderDetails',
    name: 'orderDetails',
    component: () => import('@/views/berthPanoramic/orderDetails/index.vue'),
    meta: { name: '泊位全景-订单详情', type: 'list' }
  },
  // 修改车牌
  {
    path: '/alterPlateNumber',
    name: 'alterPlateNumber',
    component: () => import('@/views/berthPanoramic/alterPlateNumber/index.vue'),
    meta: { name: '泊位全景-修改车辆', type: 'list' }
  },

  // 停车订单
  {
    path: '/parkingOrder',
    name: 'parkingOrder',
    component: () => import('@/views/parkingOrder/index.vue'),
    meta: {
      name: '停车订单',
      type: 'list'
    }
  },
  // 停车订单详情
  {
    path: '/parkOrderDetails',
    name: 'parkOrderDetails',
    component: () => import('@/views/parkingOrder/parkOrderDetails/index.vue'),
    meta: {
      name: '停车订单详情',
      type: 'list'
    }
  },
  // 停车订单 订单补缴
  {
    path: '/lackOrderPay',
    name: 'lackOrderPay',
    component: () => import('@/views/parkingOrder/lackOrderPay/index.vue'),
    meta: {
      name: '停车订单-订单补缴',
      type: 'list'
    }
  },
  // 计费规则
  {
    path: '/billRuleDetail',
    name: 'billRuleDetail',
    component: () => import('@/views/commonPage/billRuleDetail/index.vue'),
    meta: {
      name: '计费规则',
      type: 'list'
    }
  },
  //  签到/换班
  {
    path: '/signIn',
    name: 'signIn',
    component: () => import('@/views/signIn/index.vue'),
    meta: {
      name: ' 签到/换班',
      type: 'list'
    }
  },
  //  签到记录
  {
    path: '/signInRemark',
    name: 'signInRemark',
    component: () => import('@/views/signIn/signInRemark/index.vue'),
    meta: {
      name: '签到记录',
      type: 'list'
    }
  },
  // 维护管理
  {
    path: '/maintentManage',
    name: 'maintentManage',
    component: () => import('@/views/maintentManage/index.vue'),
    meta: {
      name: '维护管理',
      type: 'list'
    }
  },
  // 维护管理 上报工单
  {
    path: '/uploadWorkList',
    name: 'uploadWorkList',
    component: () => import('@/views/maintentManage/uploadWorkList/index.vue'),
    meta: {
      name: '维护管理-上报工单',
      type: 'list'
    }
  },
  // 维护管理 已完成工单
  {
    path: '/completedWork',
    name: 'completedWork',
    component: () => import('@/views/maintentManage/completedWork/index.vue'),
    meta: {
      name: '维护管理-已完成工单',
      type: 'list'
    }
  },
  // 维护管理 已完成工单 已完成工单详情
  {
    path: '/completedWorkDetails',
    name: 'completedWorkDetails',
    component: () => import('@/views/maintentManage/completedWork/completedWorkDetails/index.vue'),
    meta: {
      name: '维护管理-已完成工单',
      type: 'list'
    }
  },
  // 维护管理 待确认工单
  {
    path: '/waitConfirmWork',
    name: 'waitConfirmWork',
    component: () => import('@/views/maintentManage/waitConfirmWork/index.vue'),
    meta: {
      name: '维护管理-待确认工单',
      type: 'list'
    }
  },
  // 维护管理 待确认工单详情
  {
    path: '/waitConfirmWorkDetails',
    name: 'waitConfirmWorkDetails',
    component: () => import('@/views/maintentManage/waitConfirmWork/waitConfirmWorkDetails/index.vue'),
    meta: {
      name: '维护管理-待确认工单-待确认工单详情',
      type: 'list'
    }
  },
  // 维护管理 待确认工单详情 问题确认
  {
    path: '/waitConfirmWorkConfirm',
    name: 'waitConfirmWorkConfirm',
    component: () => import('@/views/maintentManage/waitConfirmWork/waitConfirmWorkDetails/waitConfirmWorkConfirm/index.vue'),
    meta: {
      name: '维护管理-待确认工单-待确认工单详情-问题确认',
      type: 'list'
    }
  },

  // 维护管理 待处理工单
  {
    path: '/waitDisposeWork',
    name: 'waitDisposeWork',
    component: () => import('@/views/maintentManage/waitDisposeWork/index.vue'),
    meta: {
      name: '维护管理-待处理工单',
      type: 'list'
    }
  },
  // 维护管理 待处理工单详情
  {
    path: '/waitDisposeWorkDetails',
    name: 'waitDisposeWorkDetails',
    component: () => import('@/views/maintentManage/waitDisposeWork/waitDisposeWorkDetails/index.vue'),
    meta: {
      name: '维护管理-待处理工单-待处理工单详情',
      type: 'list'
    }
  },
  // 维护管理 维护处理确认
  {
    path: '/waitDisposeWorkDetailsConfirm',
    name: 'waitDisposeWorkDetailsConfirm',
    component: () => import('@/views/maintentManage/waitDisposeWork/waitDisposeWorkDetails/waitDisposeWorkDetailsConfirm/index.vue'),
    meta: {
      name: '维护管理-待处理工单-待处理工单详情-维护处理确认',
      type: 'list'
    }
  },

  // 维护管理 待复核工单
  {
    path: '/waitPrecheckWork',
    name: 'waitPrecheckWork',
    component: () => import('@/views/maintentManage/waitPrecheckWork/index.vue'),
    meta: {
      name: '维护管理-待复核工单',
      type: 'list'
    }
  },
  // 维护管理 待复核工单详情
  {
    path: '/waitPrecheckWorkDetails',
    name: 'waitPrecheckWorkDetails',
    component: () => import('@/views/maintentManage/waitPrecheckWork/waitPrecheckWorkDetails/index.vue'),
    meta: {
      name: '维护管理-待复核工单待复核工单详情-待复核工单详情',
      type: 'list'
    }
  },
  // 测试页
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/test/index.vue'),
    meta: {
      name: '用户端-测试',
      type: 'list'
    }
  },
  // 出场已收费
  {
    path: '/outCharged',
    name: 'outCharged',
    component: () => import('@/views/test/outCharged/index.vue'),
    meta: { name: '出场已收费', type: 'list' }
  }
]

const router = new VueRouter({
  routes,
  base: process.env.BASE_URL
})

// 全局路由守卫
router.beforeEach((to, from, next) => {
  // 需要绑定停车场才能进入以下路径
  const path = ['/outCharge', '/register', '/parkingOrder', '/signIn']
  if (path.indexOf(to.path) !== -1) {
    // 判断是否绑定停车场v
    if (!Vue.prototype.$cookie.get('managePark')) {
      Toast('请先绑定停车场')
      next('/homePage')
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
