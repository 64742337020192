/*
 * @Description:维护管理 maintentManage
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-08-20 14:22:48
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/operate'

// 获取工单个数
export function queryWorkTicketCount (param) {
  return request({
    url: prefix + '/workTicket/queryWorkTicketCount',
    method: 'post',
    data: param
  })
}
