/*
 * @Description:下拉菜单查询 queryDict
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-28 10:41:10
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/operate'
const prefix1 = '/bill'

// 取证图片
export function getEvidenceByOrderSequence (param) {
  return request({
    url: prefix + '/orderEvidence/getEvidenceByOrderSequence',
    method: 'post',
    data: param
  })
}

// 下拉框查询
export function queryDict (param) {
  return request({
    url: prefix + '/dict/queryDict',
    method: 'post',
    data: param
  })
}

// 上传图片
export function upFile (param) {
  return request({
    url: prefix + '/file/upFile',
    method: 'post',
    data: param
  })
}

// 计费规则
export function queryBillingRuleById (param) {
  return request({
    url: prefix1 + '/BillingRuleController/queryBillingRuleById',
    method: 'post',
    data: param
  })
}

// 根据停车场编号查询停车场信息
export function queryParkInfo (param) {
  return request({
    url: prefix + '/ParkController/getParkInfoById',
    method: 'post',
    data: param
  })
}
