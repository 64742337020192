/*
 * @Description: 维护管理 待复核工单 waitPrecheckWork
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-08-20 14:23:40
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'
const prefix = '/operate'

// 工单查询
export function queryWorkTicketList (param) {
  return request({
    url: prefix + '/workTicket/queryWorkTicketList',
    method: 'post',
    data: param
  })
}

// 查询运维维护单位
export function queryMaintenance (param) {
  return request({
    url: prefix + '/maintenance/queryMaintenance',
    method: 'post',
    data: param
  })
}
// 查询设备信息
export function queryDeviceInfo (param) {
  return request({
    url: prefix + '/workTicket/queryDeviceInfo',
    method: 'post',
    data: param
  })
}

// 获取处理记录
export function queryWorkTicketDealt (param) {
  return request({
    url: prefix + '/workTicket/queryWorkTicketDealt',
    method: 'post',
    data: param
  })
}
