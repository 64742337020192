/*
 * @Description: 签到 signIn
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-09 14:33:16
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/operate'
const preFix = '/order'

// 巡检员上报位置
export function reportLocation (param) {
  return request({
    url: preFix + '/inspection/reportLocation',
    method: 'post',
    data: param
  })
}

// 签到
export function signIn (param) {
  return request({
    url: prefix + '/operator/signIn',
    method: 'post',
    data: param
  })
}
// 签退
export function signOut (param) {
  return request({
    url: prefix + '/operator/signOut',
    method: 'post',
    data: param
  })
}
// 今日排班
export function todaySchedule (param) {
  return request({
    url: prefix + '/operator/todaySchedule',
    method: 'get',
    data: param
  })
}
// 考勤记录
export function getSchedule (param) {
  return request({
    url: prefix + '/operator/getSchedule',
    method: 'post',
    data: param
  })
}

// 获取当前选中停车场的经纬度
export function queryParkAll (param) {
  return request({
    url: prefix + '/ParkController/queryParkAll',
    method: 'post',
    data: param
  })
}
