/*
 * @Description: 车牌登记 register
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-28 10:39:26
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/operate'
const prefix2 = '/order'

// 根据车牌号车牌颜色获取订单
export function queryOnlineOrderBySinglePlateNumber (param) {
  return request({
    url: prefix2 + '/OrderController/queryOnlineOrderBySinglePlateNumber',
    method: 'post',
    data: param
  })
}

// 按照车位查询订单
export function queryOrderOnlineByParkSpaceId (param) {
  return request({
    url: prefix2 + '/OrderController/queryOrderOnlineByParkSpaceId',
    method: 'post',
    data: param
  })
}

// 是否有订单
export function getOrderOnlineByPlateNumber (param) {
  return request({
    url: prefix + '/order/getOrderOnlineByPlateNumber',
    method: 'post',
    data: param
  })
}

// 车牌颜色下拉框查询
export function queryPlateColor (param) {
  return request({
    url: prefix + '/dict/queryDict',
    method: 'post',
    data: param
  })
}

// 泊位号下拉框查询
export function queryBerthage (param) {
  return request({
    url: prefix + '/dict/queryDictWithAuth',
    method: 'post',
    data: param
  })
}

// 巡检员登记订单
export function registerOrder (param) {
  return request({
    url: prefix2 + '/inspection/registerOrder',
    method: 'post',
    data: param
  })
}

// 上传图片
export function upFile (param) {
  return request({
    url: prefix + '/file/upFile',
    method: 'post',
    data: param
  })
}

// 查询欠费订单
export function queryArrears (param) {
  return request({
    url: prefix2 + '/OrderController/getArrearsOrderInfoByPlateNumberAndCode',
    method: 'post',
    data: param
  })
}
