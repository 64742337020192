/*
 * @Description:维护管理 待确认工单 waitConfirmWork
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-08-20 14:23:25
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/operate'

// 工单查询
export function queryWorkTicketList (param) {
  return request({
    url: prefix + '/workTicket/queryWorkTicketList',
    method: 'post',
    data: param
  })
}

// 查询运维维护单位
export function queryMaintenance (param) {
  return request({
    url: prefix + '/maintenance/queryMaintenance',
    method: 'post',
    data: param
  })
}

// 查询设备信息
export function queryDeviceInfo (param) {
  return request({
    url: prefix + '/workTicket/queryDeviceInfo',
    method: 'post',
    data: param
  })
}

// 巡检员确认工单
export function confirmWorkTicket (param) {
  return request({
    url: prefix + '/workTicket/confirmWorkTicket',
    method: 'post',
    data: param
  })
}

// 短信上传
export function equipmentRepair (param) {
  return request({
    url: prefix + '/smsNotify/equipmentRepair',
    method: 'post',
    data: param
  })
}
