/*
 * @Description:登录 login
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-28 10:37:01
 * @LastEditors: houjinduo
 */
import request from '@/utils/request'

const prefix = '/login'

// 登录接口
export function loginIn (param) {
  return request({
    url: prefix + '/LoginController/loginIn',
    method: 'post',
    data: param
  })
}

// 退出接口
export function loginOut (param) {
  return request({
    url: prefix + '/LoginController/loginOut',
    method: 'post',
    data: param
  })
}

// 验证码接口
export function captchaImage (param) {
  return request({
    url: prefix + '/LoginController/loginValidateCode',
    method: 'get',
    data: param
  })
}
