/*
 * @Description:维护管理 上报工单 uploadWorkList
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-08-20 14:22:57
 * @LastEditors: zhoucheng
 */
import request from '@/utils/request'

const prefix = '/operate'

// 巡检员上报工单
export function createWorkTicketByInspection (param) {
  return request({
    url: prefix + '/workTicket/createWorkTicketByInspection',
    method: 'post',
    data: param
  })
}
// 查询设备类型
export function queryDeviceInfo (param) {
  return request({
    url: prefix + '/workTicket/queryDeviceInfo',
    method: 'post',
    data: param
  })
}
// 根据设备获取运维单位信息
export function getMaintenanceByDevice (param) {
  return request({
    url: prefix + '/maintenance/getMaintenanceByDevice',
    method: 'post',
    data: param
  })
}
